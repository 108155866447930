$c-primary: #303230;
$c-secandary: #303230;
$dots: false;
$version: 3.1;

$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$project-font-path}Roboto-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Roboto', sans-serif;

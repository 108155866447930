
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formContainer {
  margin-top: 24px;
}

.formInputRow {
  position: relative;
  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 30%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .wrapper_default {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    background-color: var(--page-background-color);
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: -0.01875rem; //-0.332143px;
    z-index: 34;
  }

  .wrapper_anz_style {
    display: flex;
    justify-content: stretch;
    height: 100%;
    flex-direction: column;
    align-content: center;
    background-color: var(--page-background-color);
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: -0.01875rem; //-0.332143px;
    z-index: 34;
  }

  .content {
    padding: 2rem;
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.5px;
  }

  .title {
    font-size: var(--base-font-size) !important;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }

  .spinner1 {
    margin-bottom: 40px;
    margin-left: 23px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    box-shadow: 24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2),
      -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4);
    -webkit-animation: spin ease infinite 2s;
  }
  @-webkit-keyframes spin {
    0%,
    100% {
      box-shadow: 24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2),
        -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4);
    }
    25% {
      box-shadow: -24px 24px var(--loading-spinner-dot4), -24px -24px var(--loading-spinner-dot1),
        24px -24px var(--loading-spinner-dot2), 24px 24px var(--loading-spinner-dot3);
    }
    50% {
      box-shadow: -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4),
        24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2);
    }
    75% {
      box-shadow: 24px -24px var(--loading-spinner-dot2), 24px 24px var(--loading-spinner-dot3),
        -24px 24px var(--loading-spinner-dot4), -24px -24px var(--loading-spinner-dot1);
    }
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .error {
    font-size: 12px;
    color: red;
    padding: 7px 15px;
  }
}

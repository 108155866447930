
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 34;

  * {
    color: white;
  }

  .animation {
    height: 100%;
  }

  // remove animation button
  svg {
    g[transform='matrix(1,0,0,1,187.5,573.5)'],
    g[transform='matrix(1,0,0,1,187.5,585.5)'] {
      display: none !important;
    }
  }

  .button {
    background: none !important;
    border-color: #fff !important;
    font-size: 18px;
    opacity: 0;
    color: white !important;
    height: auto !important;
    transition: opacity 0.7s ease-in;
    position: fixed;
    bottom: 50px;
    min-width: 200px;

    &.open {
      opacity: 1;
    }
  }
}

@media (min-width: 675px) {
  .wrapper {
    .animation {
      height: 80%;
    }

    .button {
      display: block;
      position: relative;
      margin: auto;
      margin-top: -40px;
    }
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 1.5rem 1.5rem 6rem 1.5rem;
  background: var(--page-background-color);
  z-index: 1000;

  .img-container {
    img {
      max-width: 100%;
      height: 3.375rem; //54
      object-fit: contain;
    }
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-top: 2.5rem; //40
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }

  .title {
    font-size: 1rem;
    margin-top: 1.5rem; //24
  }
}

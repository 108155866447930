
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-family: 'Gilroy', Arial, Helvetica, sans-serif;
  letter-spacing: 0.4px;

  .frame {
    background-image: url(_img('liveness/frame.svg'));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 150px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &::after {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      top: 150px;
      left: 0;
      z-index: 3;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.success::after {
      background-image: url(_img('liveness/frame-success.svg'));
    }

    &.error::after {
      background-image: url(_img('liveness/frame-error.svg'));
    }

    .header {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 3;
      width: 100%;
      height: 150px;
      color: #fff;
      background: rgb(0, 0, 0);

      .animation {
        height: 85px;

        svg {
          height: 120px !important;
          width: 100% !important;
        }

        &.dire.right {
          transform: scaleX(-1);
        }
      }

      h4 {
        margin-top: 10px;
        text-align: center;
        animation: opacity-translate 0.8s;
        color: white;
        font-weight: 500;

        span {
          color: #14ffc0;

          &.error {
            color: #ff4747;
          }
        }
      }
    }

    &.repeat {
      .header {
        .animation {
          svg {
            position: fixed;
            height: 40% !important;
            opacity: 0.6;
            margin: auto;
            top: 110%;
            bottom: 100%;
          }
        }
      }
    }
  }

  .audio {
    position: absolute;
    z-index: 5;
    bottom: 20px;
    left: 30px;
    width: 200px;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    padding: 10px 0 10px 55px;

    i {
      position: absolute;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      left: 0;
      top: -8px;

      &::before {
        content: '';
        position: relative;
        width: 20px;
        height: 16px;
        margin: auto;
        background-image: url(_img('liveness/sprite@2x.png'));
        background-position: 0px 0px;
        background-repeat: no-repeat;
        background-size: 21px;
        display: block;
        top: 11px;
      }
    }

    &.muted {
      i {
        &::before {
          background-position: 0px -16px;
        }
      }
    }
  }

  svg image {
    display: none;
  }
  svg {
    path[stroke-linejoin='miter'] {
      stroke: white;
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacity-translate {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (max-height: 600px) {
  .wrapper .frame {
    top: 130px;

    &::after {
      top: 130px;
    }

    .header {
      height: 130px;

      .animation {
        height: 70px;

        svg {
          height: 100px !important;
        }
      }
    }
  }
}

@media (max-height: 486px) {
  .wrapper .frame {
    top: 100px;

    &::after {
      top: 100px;
    }

    .header {
      height: 100px;

      .animation {
        height: 55px;

        svg {
          height: 80px !important;
        }
      }
    }
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 34;

  * {
    color: white;
  }

  .title {
    color: white !important;
    font-size: 19px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 30px;
  }

  .icon {
    width: 100%;

    svg {
      max-height: 380px;
    }
  }

  .button {
    color: white !important;
    background: none !important;
    border-color: #fff !important;
    font-family: var(--base-font-family) !important;
    width: 200px;
    opacity: 0;
    height: 41px !important;
    transition: opacity 0.7s ease-in;

    &.open {
      opacity: 1;
    }
  }
}

@media (max-width: 750px) {
  .wrapper .icon,
  .wrapper .icon svg {
    max-height: 230px;
  }
}

@media (max-height: 321px) {
  .wrapper .icon,
  .wrapper .icon svg {
    max-height: 180px;
  }
}

@media (max-height: 265px) {
  .wrapper .icon {
    max-height: 180px;

    svg {
      margin-top: -15px;
    }
  }
}

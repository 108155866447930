
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  padding-top: 100px;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    padding: 0 20px;
    margin: auto;
    width: 100%;
  }

  .footer {
    padding: 0px 20px 20px;
    width: 100%;

    button {
      max-width: 265px;
      display: block;
      border-radius: 4px;
    }

    [data-logo] {
      margin: 0 auto 25px;
      display: block;
      width: 100px;
    }
  }

  .privacyFooter {
    position: fixed;
    bottom: 0;
    background-color: $c-grey-light;
  }

  &.isLandscope {
    padding-top: 0;
    justify-content: center;
    align-content: center;

    .footer {
      display: none;
    }
  }

  &.dark {
    background: #000;
    color: #fff;
    padding-top: 50px;

    div [class^='b-icon-warning'] {
      background: none;
      border: 1px solid;
    }

    .footer {
      button {
        background: none;
        border-color: #fff;
        color: white !important;
      }
    }
  }
}


            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  padding-left: 25px;

  label {
    display: block;
    position: relative;
    align-items: center;
    min-height: 28px;
    padding-left: 15px;
  }

  label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
    margin-left: -25px;
    border-radius: 3px;
    border: 3px solid var(--primary);
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  label::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 16px;
    margin-left: -25px;
    top: 6px;
    left: 9px;
    margin-top: -3px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    background: none !important;
    transform: rotate(45deg);
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + label::before,
  input:checked + label::after {
    background: var(--primary);
    display: block;
  }
}

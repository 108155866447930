
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/egl/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.titlePage {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem;
}
